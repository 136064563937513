import { Playlist, Plus } from '@iheartradio/web.accomplice/icons';
import {
  Menu,
  MenuItem,
  MenuPopover,
  MenuSeparator,
  SubmenuTrigger,
} from '@iheartradio/web.accomplice/menu';
import { ampContract } from '@iheartradio/web.api/amp';
import { isFunction } from '@iheartradio/web.utilities';
import {
  removeNilValuesFromObject,
  toFormData,
} from '@iheartradio/web.utilities/object';
import { useFetcher } from '@remix-run/react';
import { useCallback, useEffect } from 'react';
import { useAsyncList } from 'react-stately';
import { $path } from 'remix-routes';

import type { PlaylistCollection } from '~app/api/types';
import type {
  AddToCollectionAction,
  AddToCollectionActionData,
} from '~app/routes/api.v1.collection.add';
import type { ListCollectionsLoaderData } from '~app/routes/api.v1.collection.list';
import { isDoneFetching } from '~app/utilities/fetcher';

import {
  type CreatePlaylistSubmitCallback,
  CreatePlaylistDialog,
} from '../dialogs/create-playlist-dialog';

export type AddToCollectionSubmitCallback = (
  playlist?: AddToCollectionActionData,
) => void;

export type AddToPlaylistMenuItemsProps = {
  tracks?: number[];
  albumId?: number;
  triggerText?: string;
  onAddSubmit?: AddToCollectionSubmitCallback;
  onCreateSubmit: CreatePlaylistSubmitCallback;
  icon?: boolean;
};

type AddToPlaylistMenuRow =
  | { type: 'create-playlist'; id: string }
  | { type: 'playlist'; data: PlaylistCollection; id: string; index: number };

const playlistLibraryRoute = $path('/api/v1/collection/list');

export const AddToPlaylistSubMenu = (props: AddToPlaylistMenuItemsProps) => {
  const {
    tracks,
    albumId,
    icon,
    onAddSubmit,
    onCreateSubmit,
    triggerText = 'Add to playlist',
  } = props;

  const addToCollectionFetcher = useFetcher<AddToCollectionAction>();

  const list = useAsyncList<PlaylistCollection>({
    async load({ signal, cursor }) {
      const searchParams = new URLSearchParams(
        ampContract.v3.collection.getCollections.query
          .transform(removeNilValuesFromObject)
          .parse({
            pageKey: cursor,
            playlistFilter: 'created',
          }),
      );
      const res = await fetch(`${playlistLibraryRoute}?${searchParams}`, {
        signal,
      });

      const json = (await res.json()) as ListCollectionsLoaderData;
      return {
        items: json?.data?.data ?? [],
        cursor: json?.data?.links?.nextPageKey,
      };
    },
  });

  const items: Array<AddToPlaylistMenuRow> = [
    { type: 'create-playlist', id: 'create-playlist' },
    ...list.items.map((item, index) => ({
      type: 'playlist' as const,
      data: item,
      id: `playlist-${item.id}`,
      index,
    })),
  ];

  const submitAddToPlaylist = useCallback(
    ({ tracks, collectionId }: { tracks?: number[]; collectionId: string }) => {
      const namedAction = tracks?.length ? 'byTracks' : 'byAlbum';

      addToCollectionFetcher.submit(
        toFormData({
          tracks,
          collectionId,
          intent: namedAction,
          albumId,
        }),
        {
          method: 'POST',
          action: $path('/api/v1/collection/add'),
        },
      );
    },
    [addToCollectionFetcher, albumId],
  );

  useEffect(() => {
    if (isDoneFetching(addToCollectionFetcher) && isFunction(onAddSubmit)) {
      onAddSubmit(addToCollectionFetcher?.data);
    }
  }, [addToCollectionFetcher, onAddSubmit]);

  return (
    <SubmenuTrigger data-test="add-to-playlist-sub-menu">
      {icon ?
        <MenuItem>
          <Playlist size={18} /> {triggerText}
        </MenuItem>
      : <MenuItem>{triggerText}</MenuItem>}

      <MenuPopover>
        <Menu<AddToPlaylistMenuRow> items={items}>
          {row => {
            return (
              row.type === 'create-playlist' ?
                <>
                  <CreatePlaylistDialog
                    albumId={albumId}
                    onSubmit={onCreateSubmit}
                    tracks={tracks}
                    trigger={
                      <MenuItem
                        closeOnSelect={false}
                        data-test="create-playlist-menu-item"
                      >
                        <Plus />
                        Create new playlist
                      </MenuItem>
                    }
                  />
                  <MenuSeparator />
                </>
              : row.type === 'playlist' ?
                <MenuItem
                  closeOnSelect={false}
                  onAction={() => {
                    submitAddToPlaylist({ tracks, collectionId: row.data.id });
                  }}
                >
                  {row.data.name}
                </MenuItem>
              : null
            );
          }}
        </Menu>
      </MenuPopover>
    </SubmenuTrigger>
  );
};

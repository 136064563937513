import { type IconProps, Icon } from './icon.js';

export function Playlist(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Playlist" {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M16.3037 3H4.33037C3.5986 3 3 3.59506 3 4.32236s.5986 1.32236 1.33037 1.32236H16.3037c.7317 0 1.3303-.59506 1.3303-1.32236S17.0354 3 16.3037 3Zm0 5.28944H4.33037C3.5986 8.28944 3 8.8845 3 9.61179c0 .72731.5986 1.32241 1.33037 1.32241H16.3037c.7317 0 1.3303-.5951 1.3303-1.32241 0-.72729-.5986-1.32235-1.3303-1.32235ZM4.33037 13.5789h6.65183c.7318 0 1.3304.595 1.3304 1.3223 0 .7273-.5986 1.3224-1.3304 1.3224H4.33037C3.5986 16.2236 3 15.6285 3 14.9012c0-.7273.5986-1.3223 1.33037-1.3223Zm10.64293 6.7572v-5.5936c0-.5157.5587-.833 1.0111-.5818l4.6962 2.8034c.4259.2645.4259.886 0 1.1372l-4.6962 2.8034c-.4524.2645-1.0111-.0529-1.0111-.5686Z" />
      </svg>
    </Icon>
  );
}

import { lightDark, vars } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import { Button } from '@iheartradio/web.accomplice/button';
import {
  Dialog,
  DialogTitle,
  DialogTrigger,
} from '@iheartradio/web.accomplice/dialog';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Loading } from '@iheartradio/web.accomplice/icons';
import { Message } from '@iheartradio/web.accomplice/message';
import { TextField } from '@iheartradio/web.accomplice/text-field';
import { addToast } from '@iheartradio/web.accomplice/toast';
import { isFunction } from '@iheartradio/web.utilities';
import { useFetcher, useNavigate } from '@remix-run/react';
import {
  type ReactNode,
  type Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isNullish } from 'remeda';
import { $path } from 'remix-routes';

import type {
  CreateCollectionAction,
  CreateCollectionActionData,
} from '~app/routes/api.v1.collection.create';
import { INPUT_MAX_LENGTH_DEFAULT } from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';

export type CreatePlaylistSubmitCallback = (
  playlist: CreateCollectionActionData,
) => void;

// Either the `albumId` or `tracks` key should be set, but not both.
export type CreatePlaylistDialogProps = {
  onSubmit?: CreatePlaylistSubmitCallback;
  setMenuOpen?: (open: boolean) => void;
  trigger: ReactNode;
  albumId?: number;
  tracks?: number[];
  triggerIsButton?: boolean;
};

export const CreatePlaylistDialog = (props: CreatePlaylistDialogProps) => {
  const { onSubmit, trigger, setMenuOpen, tracks, albumId } = props;

  const [playlistNameIsValid, setPlaylistNameIsValid] = useState(false);
  const ref = useRef<HTMLFormElement>();
  const navigate = useNavigate();
  const [characterCount, setCharacterCount] = useState(0);

  const createCollection = useFetcher<CreateCollectionAction>();

  const isSubmitting = createCollection.state === 'submitting';
  const isLoading = createCollection.state === 'loading';
  const isDone =
    createCollection.state === 'idle' && createCollection.data != null;
  const isSuccessful = isDone && createCollection.data?.ok;

  const errorMessage =
    createCollection.data?.ok ? null : createCollection.data?.errorMessage;

  const handleSubmit = useCallback<CreatePlaylistSubmitCallback>(
    data => {
      if (data.ok) {
        addToast({
          kind: 'success',
          text: 'Playlist created',
          actions: [
            {
              kind: 'tertiary',
              color: 'gray',
              content: 'Go to playlist',
              size: 'large',
              textColor: vars.color.gray600,
              onPress: () => {
                navigate(buildPlaylistUrl(data.collection));
              },
            },
          ],
        });
      }

      setMenuOpen?.(false);
    },
    [navigate, setMenuOpen],
  );

  // Reset the form on submission
  useEffect(() => {
    if (isSuccessful) {
      ref?.current?.reset();
    }
  }, [isSuccessful]);

  useEffect(() => {
    if (isDone && createCollection?.data) {
      if (isFunction(onSubmit)) {
        onSubmit(createCollection.data);
      } else {
        handleSubmit(createCollection?.data);
      }
    }
  }, [createCollection, handleSubmit, isDone, onSubmit]);

  return (
    <DialogTrigger isDismissable>
      {trigger}
      {close => (
        <Dialog>
          <createCollection.Form
            action={$path('/api/v1/collection/create')}
            method="POST"
            ref={ref as Ref<HTMLFormElement>}
          >
            <Flex flexDirection="column" gap="$16">
              <Box asChild color={lightDark('$gray600', '$brandWhite')}>
                <DialogTitle>Create New Playlist</DialogTitle>
              </Box>
              <Box>
                <TextField
                  isRequired
                  label="Playlist Name"
                  maxLength={INPUT_MAX_LENGTH_DEFAULT}
                  name="name"
                  onChange={value => {
                    setCharacterCount(value.length);
                    setPlaylistNameIsValid(value.trim() !== '');
                  }}
                  placeholder="Enter playlist name"
                />

                <Message kind="neutral">
                  {characterCount} / {INPUT_MAX_LENGTH_DEFAULT}
                </Message>
              </Box>
              {errorMessage ?
                <Message key={errorMessage} kind="error">
                  {errorMessage}
                </Message>
              : null}
              {isNullish(albumId) && tracks ?
                tracks.map((trackId, index) => (
                  <input
                    key={trackId}
                    name={`tracks[${index}]`}
                    type="hidden"
                    value={trackId}
                  />
                ))
              : albumId && !tracks?.length ?
                <input name="albumId" type="hidden" value={albumId} />
              : null}
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                gap="$16"
                justifyContent="center"
              >
                <Button
                  color={{ dark: 'white', light: 'gray' }}
                  inline
                  kind="secondary"
                  onPress={() => setTimeout(close, 100)}
                  size={{ mobile: 'small', medium: 'large' }}
                  type="reset"
                >
                  Cancel
                </Button>
                <Button
                  color="red"
                  inline
                  isDisabled={isSubmitting || isLoading || !playlistNameIsValid}
                  kind="primary"
                  size={{ xsmall: 'small', medium: 'large' }}
                  type="submit"
                >
                  {createCollection.state === 'idle' ?
                    'Create playlist'
                  : <Loading size={16} />}
                </Button>
              </Box>
            </Flex>
          </createCollection.Form>
        </Dialog>
      )}
    </DialogTrigger>
  );
};
